import { ROLE } from 'constant/role';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

export const getNavigationCategory = (role: string) => {
  switch (role) {
    case ROLE.ADMIN:
      return [
        {
          id: 'Quản trị tài khoản',
          children: [
            {
              id: 'Thêm tài khoản',
              icon: <PersonAddAltOutlinedIcon />,
              path: 'add-account',
            },
            {
              id: 'Danh sách tài khoản',
              icon: <GroupsOutlinedIcon />,
              path: 'account-list',
            },
          ],
        },
      ];

    case ROLE.ACCOUNTANT:
      return [
        {
          id: 'Quản trị biên lai',
          children: [
            {
              id: 'Thêm biên lai',
              icon: <PersonAddAltOutlinedIcon />,
              path: 'add-bill',
            },
            {
              id: 'Danh sách biên lai',
              icon: <MonetizationOnOutlinedIcon />,
              path: 'bill-list',
            },
          ],
        },
      ];

    default:
      return [];
  }
};
