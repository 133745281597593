import { useAppContext } from 'Context/AppContext';
import { PaidBillList, UnpaidBillList } from './Tabs';

export const BillList = () => {
  const { tabNumber } = useAppContext();

  if (tabNumber === 0) return <UnpaidBillList />;
  if (tabNumber === 1) return <PaidBillList />;

  return <></>;
};
