import { useAppContext } from 'Context/AppContext';
import { AccountantList, AdminList, Principal, TeacherList } from './Tabs';

export const AccountList = () => {
  const { tabNumber } = useAppContext();

  if (tabNumber === 0) return <AdminList />;
  if (tabNumber === 1) return <Principal />;
  if (tabNumber === 2) return <TeacherList />;
  if (tabNumber === 3) return <AccountantList />;

  return <></>;
};
