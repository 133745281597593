import React from 'react';
import { Button, Card, Col, Form, Modal, Row, Upload } from 'antd';
import { toast } from 'react-toastify';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { UploadOutlined } from '@ant-design/icons';
import { ResponseDataType, postRequest } from 'api';
import { useAppContext } from 'Context/AppContext';
import styles from '../../bill.module.css';

interface InputFileBill {
  fileBill: any;
}

interface FormValueType {
  file: any;
}

export const CreateNewBill = () => {
  const [form] = Form.useForm();
  const { tabNumber, setTabNumber } = useAppContext();

  const HandleInputFile = (value: FormValueType) => {
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận thêm biên lai.',
      async onOk() {
        const formData = new FormData();
        formData.append('file', value.file[0].originFileObj);

        const response: ResponseDataType<InputFileBill> = await postRequest('/cms/invoice/import-invoices-excel', formData, 'form-data');
        console.log('response', response.code);
        if (response) {
          if (response.code === 200) {
            toast.success('Thêm biên lai thành công!');
          } else {
            toast.error('Thêm biên lai thất bại!');
            // Change tab in case of error
            setTabNumber(1);
          }
        } else {
          toast.error('Thêm biên lai thất bại!');

        }

      },
    });
  };

  return (
    <div>
      <Card title="Thêm biên lai" className={styles.bill_info_card}>
        <Form form={form} onFinish={HandleInputFile}>
          <Row>
            <Col span={8}>
              <div className={styles.card_content_label}>
                <InsertDriveFileIcon />
                &nbsp;&nbsp;File:
              </div>
            </Col>
            <Col span={16}>
              <Form.Item
                name="file"
                valuePropName="fileList"
                getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
                rules={[
                  { required: true, message: 'Cần nhập file!' },
                  {
                    validator: (_, value) =>
                      value && value.length > 0 && /\.(xlsx|xls)$/i.test(value[0].name)
                        ? Promise.resolve()
                        : Promise.reject(new Error('Chỉ chấp nhận file Excel!')),
                  },
                ]}
              >
                <Upload beforeUpload={() => false}>
                  <Button icon={<UploadOutlined />}>Chọn file</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Button type="primary" htmlType="submit">
            IMPORT FILE
          </Button>
        </Form>
      </Card>
    </div>
  );
};
