import { Card, CircularProgress, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import { getRequest, ResponseDataType } from 'api';
import React, { useEffect, useState } from 'react';
import styles from './home.module.css';
import { ROLE } from 'constant/role';

const card_sx = {
  color: '#141D49',
  padding: '10px',
  width: '250px',
  cursor: 'pointer',
};

const circular_progress_sx = {
  color: '#141D49',
};

interface GeneralInfoDataType {
  sumAccountants: number;
  sumTeachers: number;
  sumParents: number;
  sumStudents: number;
  sumSchools: number;
  sumInvoices: number;
}

export const Home = (): JSX.Element | null => {
  const [generalInfo, setGeneralInfo] = useState<GeneralInfoDataType | null>(null);
  const schoolId = localStorage.getItem('schoolId');
  const role = localStorage.getItem('role');

  const isAdmin = role === ROLE.ADMIN;
  const isAccountant = role === ROLE.ACCOUNTANT;

  useEffect(() => {
    const fetchData = async (url: string) => {
      const response: ResponseDataType<GeneralInfoDataType> = await getRequest(url);
      // console.log(response);
      if (response) {
        if (response.code === 200 && response.info) {
          setGeneralInfo(response.info);
        } else {
          console.log(response.msg);
        }
      }
    };

    if (isAdmin) {
      const queryParams = new URLSearchParams({
        schoolId: schoolId || '',
      });
      const url = `/cms/customer/home?${queryParams.toString()}`;
      fetchData(url);  // Gọi fetchData một lần
    } else if (isAccountant) {
      const queryParams = new URLSearchParams({
        schoolId: schoolId || '',
      });
      const url = `/cms/invoice/home?${queryParams.toString()}`;
      fetchData(url);  // Gọi fetchData một lần
    }
  }, [isAdmin, isAccountant, schoolId]);  // Mảng dependencies đảm bảo useEffect chỉ chạy khi các giá trị này thay đổi

  if (isAdmin) {
    return (
      <Grid container spacing={5}>
        <Grid item>
          <Link style={{ textDecoration: 'none' }} to='/school-list'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Trường</p>
              <p className={styles.card_number}>
                {generalInfo ? generalInfo.sumSchools : <CircularProgress sx={circular_progress_sx} />}
              </p>
              <Grid container justifyContent='flex-end'>
                <DomainOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
        <Grid item>
          <Link style={{ textDecoration: 'none' }} to=''>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Giáo viên</p>
              <p className={styles.card_number}>
                {generalInfo ? generalInfo.sumTeachers : <CircularProgress sx={circular_progress_sx} />}
              </p>
              <Grid container justifyContent='flex-end'>
                <PeopleAltOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
        <Grid item>
          <Link style={{ textDecoration: 'none' }} to='/'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Phụ huynh</p>
              <p className={styles.card_number}>
                {generalInfo ? generalInfo.sumParents : <CircularProgress sx={circular_progress_sx} />}
              </p>
              <Grid container justifyContent='flex-end'>
                <PeopleAltOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
        <Grid item>
          <Link style={{ textDecoration: 'none' }} to='/'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Học sinh</p>
              <p className={styles.card_number}>
                {generalInfo ? generalInfo.sumStudents : <CircularProgress sx={circular_progress_sx} />}
              </p>
              <Grid container justifyContent='flex-end'>
                <PeopleAltOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
      </Grid>
    );
  }

  if (isAccountant) {
    return (
      <Grid container spacing={5}>
        <Grid item>
          <Link style={{ textDecoration: 'none' }} to='/bill-list'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Bill</p>
              <p className={styles.card_number}>
                {generalInfo ? generalInfo.sumInvoices : <CircularProgress sx={circular_progress_sx} />}
              </p>
              <Grid container justifyContent='flex-end'>
                <DomainOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
      </Grid>
    );
  }

  // Return null or some default content if neither condition is met
  return null;
};
