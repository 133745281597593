import React, { useEffect, useState } from 'react';
import { Table, TableProps } from 'antd';
import { Link } from 'react-router-dom';
import { getRequest, ResponseDataType } from 'api';

interface CustomerDataType {
  customerId: number;
  email: string;
  phoneNumber: string;
  typeString: string;
}

const columns: TableProps<CustomerDataType>['columns'] = [
  { title: 'Email', dataIndex: 'email', key: 'email', width: '100px' },
  { title: 'Số điện thoại', dataIndex: 'phoneNumber', key: 'phoneNumber', width: '100px' },
  { title: 'Vị trí', dataIndex: 'typeString', key: 'typeString', width: '100px' },
  {
    title: 'Hành động',
    dataIndex: 'customerId',
    key: 'action',
    width: '140px',
    render: (customerId) => (
      <Link to={`/account-list/account-info/${customerId.toString()}`}>Chi tiết</Link>
    ),
  },
];

export const TeacherList = () => {
  const [data, setData] = useState<CustomerDataType[]>([]);

  const page = 1;
  const perPage = 10;
  const schoolid = localStorage.getItem('schoolId');
  // console.log('schoolId', schoolid);
  const type = 4;

  useEffect(() => {
    const queryParams = new URLSearchParams({
      page: page.toString(),
      perPage: perPage.toString(),
      schoolId: schoolid ? schoolid.toString() : '',
      type: type.toString(),
    });
    const url = `/cms/customer/get-customer-by-school-type?${queryParams.toString()}`;

    const fetchData = async () => {
      const response: ResponseDataType<{ list: CustomerDataType[]; total: number }> = await getRequest(url);
      console.log('response', response);
      if (response) {
        if (response.code === 200) {
          setData(response.info?.list || []);
        } else {
          console.log(response.msg);
        }
      }
    };
    fetchData();
  }, [page, perPage]); // Thêm các biến phụ thuộc vào mảng phụ thuộc

  return (
    <div>
      <Table columns={columns} dataSource={data} rowKey="customerId" />
    </div>
  );
};