import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, Input, Select } from 'antd';
import { toast } from 'react-toastify';

import styles from './authen-page.module.css';
import { ResponseDataType, postRequest } from 'api';
import { ROLE_ENCRYPT } from 'constant/role';

interface ChoseRoleDataType {
    id: number;
    email: string;
    roles: string[];
    token: string;
    refresh_token: string;
    schools: any[];
}

interface OptionType {
    value: number;
    label: string;
}

const typeList = ['', 'Admin trường', 'Hiệu trưởng', 'Kế toán', 'Giáo viên'];

export const ChoseRole = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const location = useLocation();
    const userData = location.state?.user;
    const [options, setOptions] = useState<OptionType[]>([]);
    const [selectedRole, setSelectedRole] = useState<number | undefined>();

    const fetchRole = async () => {
        if (userData && userData.schools && userData.schools.length > 0) {
            const schoolType = userData.schools[0].type;
            const filteredOptions: OptionType[] = [
                { value: 1, label: 'Admin trường' },
                { value: 2, label: 'Hiệu trưởng' },
                { value: 3, label: 'Kế toán' },
                { value: 4, label: 'Giáo viên' },
            ].filter(option => option.value === schoolType);
            setOptions(filteredOptions);
        }

    };

    useEffect(() => {
        fetchRole();
    }, [selectedRole]);

    const roleCase = async (values: { status: number }) => {
        console.log('Selected Role:', values.status);
        console.log('UserData:', userData);

        localStorage.setItem('email', userData.email);
        localStorage.setItem('uid', userData.id.toString());
        localStorage.setItem('accessToken', userData.token);
        localStorage.setItem('refreshToken', userData.refresh_token);
        localStorage.setItem('schoolId', userData.schools[0].schoolId.toString());

        let roleEncrypt = ROLE_ENCRYPT.find(
            (role) => role.role === userData.roles[0]
        )?.encrypted;

        localStorage.setItem('role', roleEncrypt as string);
        window.location.href = '/';
    };


    return (
        <div className={styles.login_page}>
            <div className={styles.login_page_background} />
            <div className={styles.authen_wrapper}>
                <img className={styles.logo} src='/Images/logo.png' alt='' />
                <div className={styles.name}>Vschool</div>
                <Form form={form} onFinish={(values) => roleCase(values)}>
                    <div className={`${styles.form} authen-form`} style={{ width: '100%', height: '100%' }}>
                        <Form.Item name='status' style={{ width: '100%', height: '100%', margin: 0 }}>
                            <Select
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    marginBottom: 0,
                                    fontWeight: 'bold',
                                    color: 'black',
                                    fontSize: '30px',
                                }}
                                value={selectedRole}
                                onChange={(value) => setSelectedRole(value)}
                                placeholder={
                                    typeList[userData?.schools[0].type ? userData.schools[0].type : 1]
                                }
                                options={options}
                            />
                        </Form.Item>
                    </div>
                    <Button
                        type='primary'
                        className={styles.authen_button}
                        htmlType='submit'
                    >
                        Chọn ví trí quản trị
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default ChoseRole;
