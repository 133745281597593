import * as React from 'react';
import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Radio } from 'antd';
import { UserOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { PATH_INFO } from 'router';
import { useAppContext } from 'Context/AppContext';
import { ResponseDataType, postRequest, getRequest } from 'api';
import { toast } from 'react-toastify';
import { ROLE_ENCRYPT } from 'constant/role';
import { getRoleFromEncrypted } from '../utils/roleUtils';

import styles from './layout.module.css';
import { Console } from 'console';

interface HeaderProps {
  onDrawerToggle: () => void;
}

interface PathInfoInterface {
  title: string;
  tabList?: string[];
}

interface SchoolType {
  schoolId: number;
  type: number;
  status: number;
  schoolName: string;
  mst: string;
  schoolAvatar: string;
}

interface CustomerInfoType {
  email: string;
  phoneNumber: string;
  customerId: number;
  schools: SchoolType[];
  roles: any;
  avatar: string;
}

const typeList = ['', 'Admin trường', 'Hiệu trưởng', 'Kế toán', 'Giáo viên'];


export default function Header(props: HeaderProps) {
  const { tabNumber, setTabNumber } = useAppContext();
  const { onDrawerToggle } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [pathInfo, setPathInfo] = useState<PathInfoInterface>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoType | null>(null);
  const [selectedRole, setSelectedRole] = useState<number | null>(null);
  const accountId = localStorage.getItem('uid');
  const schoolStorage = localStorage.getItem('schoolId');


  useEffect(() => {
    let info = PATH_INFO[location.pathname as keyof typeof PATH_INFO];
    if (!info) {
      info = PATH_INFO[`/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}` as keyof typeof PATH_INFO];
    }
    setPathInfo(info);

    const GetCustomerInfo = async () => {
      const response: ResponseDataType<CustomerInfoType> = await getRequest(`/cms/customer/get-info`);
      if (response.code === 200 && response.info) {
        setCustomerInfo(response.info);
        const schoolIdFromStorage = parseInt(schoolStorage || '0', 10);
        const matchingSchool = response.info.schools.find(school => school.schoolId === schoolIdFromStorage);
        if (matchingSchool) {
          setSelectedRole(response.info.schools.indexOf(matchingSchool));
        }


      } else {
        toast.error(response.msg);
      }
    };
    GetCustomerInfo();
  }, [location.pathname]);

  const handleLogout = async () => {
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận Đăng xuất?',
      async onOk() {
        await postRequest('/cms/auth/customer/log-out');
        localStorage.removeItem('email');
        localStorage.removeItem('uid');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('role');
        navigate('/login');
      },
      onCancel() { },
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    navigate('/profile');
    setAnchorEl(null);
  };

  const handleRoleChange = (e: any) => {
    const selectedIndex = e.target.value as number; // Chuyển đổi sang kiểu số
    if (customerInfo?.schools && customerInfo.schools[selectedIndex]) {
      const selectedSchoolId = customerInfo.schools[selectedIndex].schoolId;
      localStorage.setItem('schoolId', selectedSchoolId.toString());
      console.log('selectedSchoolId', selectedSchoolId);
      let roleEncrypt = ROLE_ENCRYPT.find(
        (role) => role.role === customerInfo.roles[selectedIndex]
      )?.encrypted;
      console.log('roleEncrypt', roleEncrypt);
      localStorage.setItem('role', roleEncrypt as string);
    }
    setSelectedRole(selectedIndex);
    console.log('Selected Role:', selectedIndex);
    handleMenuClose();
  };

  return (
    <React.Fragment>
      <AppBar color='primary' position='sticky' elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems='center'>
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={onDrawerToggle}
                edge='start'
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <IconButton
                onClick={handleMenuOpen}
                color='inherit'
                sx={{ p: 0.5 }}
              >
                <Avatar src={customerInfo?.avatar || "/Images/logo.png"} alt='My Avatar' />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {customerInfo?.schools.map((school, index) => (
                  <MenuItem key={school.schoolId} onClick={handleMenuClose}>
                    <Radio.Group onChange={handleRoleChange} value={selectedRole}>
                      <Radio value={index} style={{ flexDirection: 'row-reverse' }}>
                        <div className={styles.info}>
                          <Avatar src={school.schoolAvatar || "/Images/school.png"} alt={school.schoolName} />
                          <div>
                            <p>{school.schoolName}</p>
                            <small>{typeList[school.type]}</small>
                          </div>
                        </div>
                      </Radio>
                    </Radio.Group>
                  </MenuItem>
                ))}

                <MenuItem className={styles.info} onClick={handleProfile}>
                  <p>Tài khoản của tôi</p>
                  <UserOutlined />
                </MenuItem>
                <MenuItem className={styles.logout} onClick={handleLogout}>
                  <p>Đăng xuất</p>
                  <ArrowRightOutlined />
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component='div'
        color='primary'
        position='static'
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems='center' spacing={1}>
            <Grid item xs>
              <Typography color='inherit' variant='h5' component='h1'>
                {pathInfo?.title}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component='div'
        position='static'
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Tabs
          value={tabNumber}
          textColor='inherit'
          onChange={(e, value) => setTabNumber?.(value)}
        >
          {pathInfo?.tabList ? (
            pathInfo.tabList.map((e, index) => (
              <Tab value={index} label={e} key={index} />
            ))
          ) : (
            <Tab />
          )}
        </Tabs>
      </AppBar>
    </React.Fragment>
  );
}
