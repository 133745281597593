import { Button, Card, Col, Form, Input, Modal, Row, Select } from 'antd';
import { ResponseDataType, deleteRequest, getRequest, postRequest, patchRequest } from 'api';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PermIdentityOutlined from '@mui/icons-material/PermIdentityOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styles from './info.module.css';

interface CustomerInfoType {
  email: string;
  phoneNumber: string;
  customerId: number;
  schools: any[];
}
interface FormValueType {
  email: string;
  phoneNumber: string;
  customerId: number;
  schools: any[];
  role: number;
  status: number;
}

const statusList = ['', 'Đang làm việc', 'Nghỉ việc'];
const typeList = ['', 'Admin trường', 'Hiệu trưởng', 'Kế toán', 'Giáo viên'];

export const AccountInfo = () => {
  const schoolId = localStorage.getItem('schoolId');
  const { accountId } = useParams();
  // console.log('UseParams', useParams());

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoType>();

  useEffect(() => {
    const GetCustomerInfo = async () => {
      const response: ResponseDataType<CustomerInfoType> = await getRequest(
        `/cms/customer/${accountId}`
      );
      // console.log('response', response);
      if (response.code === 200) {
        setCustomerInfo(response.info);
      } else {
        toast.error(response.msg);
        // navigate('/account-list');
      }
    };
    GetCustomerInfo();
  }, [accountId, navigate]);

  const UpdateCustomerInfo = (value: FormValueType) => {
    // console.log('value', value);

    const formData = {
      "customerId": accountId,
      "email": customerInfo?.email,
      "phoneNumber": customerInfo?.phoneNumber,
      "password": 123456,
      "schools": [
        {
          "schoolId": schoolId,
          "type": value.role ? value.role : customerInfo?.schools[0].type,
          "status": value.status ? value.status : customerInfo?.schools[0].status
        }
      ]
    };
    console.log('formData', formData);


    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận cập nhật thông tin tài khoản?',
      async onOk() {
        const response: ResponseDataType<CustomerInfoType> = await patchRequest(
          '/cms/customer',
          formData,
          'json'
        );
        console.log('response', response);
        if (response) {
          if (response.code === 200) {
            toast.success('Cập nhật tài khoản thành công!');
            navigate('/account-list');
          } else {
            toast.error('Cập nhật tài khoản thất bại!');
            // Change tab in case of error
          }
        } else {
          toast.error('Cập nhật tài khoản thất bại!');
        }
      },
    });
  };

  const DeleteCustomer = () => {
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận xóa tài khoản?',
      async onOk() {
        const response: ResponseDataType<string> = await deleteRequest(
          `/cms/customer/${accountId}`
        );
        console.log('response', response);
        if (response) {
          if (response.code === 200) {
            toast.success('Xóa tài khoản thành công!');
            navigate('/account-list');
          } else {
            toast.error('Xóa tài khoản thất bại!');
            // Change tab in case of error
          }
        } else {
          toast.error('Xóa tài khoản thất bại!');
        }
      },
    });
  };

  return (
    <div>
      <Card title='Chi tiết tài khoản' className={styles.customer_info_card}>
        <Form form={form} onFinish={UpdateCustomerInfo}>
          <Row>
            <Col span={10}>
              <div
                className={styles.card_content_label}
                style={{ marginTop: 0 }}
              >
                <EmailOutlinedIcon />
                &nbsp;&nbsp;Email:
              </div>
            </Col>
            <Col span={14}>
              <div
                className={styles.card_content_label_content}
                style={{ marginTop: 0 }}
              >
                <Form.Item name='email'>
                  {customerInfo?.email}
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Col span={10}>
              <div
                className={styles.card_content_label}
                style={{ marginTop: 0 }}
              >
                <PhoneOutlinedIcon />
                &nbsp;&nbsp;Phone:
              </div>
            </Col>
            <Col
              span={14}
              style={{ width: '70%', color: '#009BE5', fontWeight: '600' }}
            >
              <Form.Item name='phoneNumber'>
                {customerInfo?.phoneNumber}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={10}>
              <div className={styles.card_content_label}>
                <PermIdentityOutlined />
                &nbsp;&nbsp;Vị trí:
              </div>
            </Col>
            <Col span={14}>
              <Form.Item name='role'>
                <Select
                  style={{
                    marginBottom: 0,
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                  allowClear
                  placeholder={
                    typeList[customerInfo?.schools[0].type ? customerInfo.schools[0].type : 1]
                  }
                  options={[
                    { value: 1, label: 'Admin trường' },
                    { value: 2, label: 'Hiệu trưởng' },
                    { value: 4, label: 'Giáo viên' },
                    { value: 3, label: 'Kế toán' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{
            marginTop: 0,
            fontWeight: 'bold',
            color: 'black',
          }}>
            <Col span={10}>
              <div className={styles.card_content_label}>
                <NotificationsNoneOutlinedIcon />
                &nbsp;&nbsp;Trạng thái:
              </div>
            </Col>
            <Col span={14}>
              <Form.Item name='status'>
                <Select
                  style={{ marginBottom: 0 }}
                  allowClear
                  placeholder={
                    statusList[customerInfo?.schools[0].status ? customerInfo.schools[0].status : 1]
                  }
                  options={[
                    { value: 1, label: 'Đang làm việc' },
                    { value: 2, label: 'Nghỉ việc' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Button type='primary' htmlType='submit'>
            Cập nhật tài khoản
          </Button>
        </Form>
      </Card>
      <div className={styles.delete_customer_button} onClick={DeleteCustomer}>
        Xóa tài khoản
      </div>
    </div >
  );
};
