import React from 'react';
import { Button, Card, Col, Form, Modal, Row, Upload, Select, Input } from 'antd';
import { toast } from 'react-toastify';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { UploadOutlined } from '@ant-design/icons';
import { ResponseDataType, postRequest } from 'api';
import { useAppContext } from 'Context/AppContext';
import styles from './info.module.css';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PermIdentityOutlined from '@mui/icons-material/PermIdentityOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useParams, useNavigate } from 'react-router-dom';
interface CreateAccountDataType {
  email: string;
  phoneNumber: string;
  password: string;
  customerId: number;
  schools: any[];
  role: number;
  status: number;
}

const statusList = ['', 'Đang làm việc', 'Nghỉ việc'];
const typeList = ['', 'Admin trường', 'Hiệu trưởng', 'Kế toán', 'Giáo viên'];

export const CreateAccount = () => {
  const [form] = Form.useForm();
  const schoolId = localStorage.getItem('schoolId');
  // console.log('schoolId', schoolId);
  const navigate = useNavigate();

  const handleCreate = async (value: CreateAccountDataType) => {
    console.log(value);
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận thêm tài khoản.',
      async onOk() {
        const formData = {
          "email": value.email,
          "password": 123456,
          "phoneNumber": value.phoneNumber,
          "schools": [
            {
              "schoolId": schoolId,
              "type": value.role,
              "status": value.status
            }
          ]

        };
        const response: ResponseDataType<CreateAccountDataType> = await postRequest('/cms/customer', formData, 'json');
        console.log('response', response);
        if (response) {
          if (response.code === 200) {
            toast.success('Thêm tài khoản thành công!');
            navigate('/account-list');
          } else {
            toast.error('Thêm tài khoản thất bại!');
            // Change tab in case of error
          }
        } else {
          toast.error('Thêm tài khoản thất bại!');
        }
      },
    });
  };



  return (
    <div>
      <Card title='Thêm tài khoản' className={styles.customer_info_card}>
        <Form form={form} onFinish={handleCreate}>
          <Row >
            <Col span={10}>
              <div
                className={styles.card_content_label}
                style={{ marginTop: 0 }}
              >
                <EmailOutlinedIcon />
                &nbsp;&nbsp;Email:
              </div>
            </Col>
            <Col
              span={14}
              style={{ width: '70%', color: '#009BE5', fontWeight: '600' }}
            >
              <Form.Item name='email'>
                <Input placeholder="Nhập email" />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Col span={10}>
              <div
                className={styles.card_content_label}
                style={{ marginTop: 0 }}
              >
                <PhoneOutlinedIcon />
                &nbsp;&nbsp;Phone:
              </div>
            </Col>
            <Col
              span={14}
              style={{ width: '70%', color: '#009BE5', fontWeight: '600' }}
            >
              <Form.Item name='phoneNumber'>
                <Input placeholder="Nhập số điện thoại" />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Col span={10}>
              <div className={styles.card_content_label}>
                <PermIdentityOutlined />
                &nbsp;&nbsp;Vị trí:
              </div>
            </Col>
            <Col span={14}>
              <Form.Item name='role'>
                <Select
                  style={{
                    marginBottom: 0,
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                  allowClear
                  options={[
                    { value: 1, label: 'Admin trường' },
                    { value: 2, label: 'Hiệu trưởng' },
                    { value: 4, label: 'Giáo viên' },
                    { value: 3, label: 'Kế toán' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{
            marginTop: 0,
            fontWeight: 'bold',
            color: 'black',
          }}>
            <Col span={10}>
              <div className={styles.card_content_label}>
                <NotificationsNoneOutlinedIcon />
                &nbsp;&nbsp;Trạng thái:
              </div>
            </Col>
            <Col span={14}>
              <Form.Item name='status'>
                <Select
                  style={{ marginBottom: 0 }}
                  allowClear
                  options={[
                    { value: 1, label: 'Đang làm việc' },
                    { value: 2, label: 'Nghỉ việc' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Button type='primary' htmlType='submit'>
            Thêm tài khoản
          </Button>
        </Form>
      </Card>
    </div >
  );
};
