import { Button, Card, Col, Form, Input, Modal, Row } from 'antd';
import { ResponseDataType, getRequest, patchRequest } from 'api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PermIdentityOutlined from '@mui/icons-material/PermIdentityOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import styles from './info.module.css';

interface CustomerInfoType {
    email: string;
    phoneNumber: string;
    customerId: number;
    schools: any[];
    admPassword: string;
    name?: string;
    avatar?: string;
}

interface FormValueType {
    email: string;
    phoneNumber: string;
    customerId: number;
    schools: any[];
    role: number;
    status: number;
    admPassword: string;
    name?: string;
    avatar?: string;
}

const statusList = ['', 'Đang làm việc', 'Nghỉ việc'];
const typeList = ['', 'Admin trường', 'Hiệu trưởng', 'Kế toán', 'Giáo viên'];

export const Profile = () => {
    const accountId = localStorage.getItem('uid');
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [customerInfo, setCustomerInfo] = useState<CustomerInfoType | undefined>(undefined);

    useEffect(() => {
        const GetCustomerInfo = async () => {
            const response: ResponseDataType<CustomerInfoType> = await getRequest(
                `/cms/customer/get-info`
            );
            if (response.code === 200 && response.info) {
                setCustomerInfo(response.info);
                form.setFieldsValue({
                    email: response.info.email,
                    phoneNumber: response.info.phoneNumber,
                    name: response.info.name,
                    avatar: response.info.avatar,
                    admPassword: '',
                    confirmPassword: ''
                });
            } else {
                toast.error(response.msg);
            }
        };
        GetCustomerInfo();
    }, [accountId, form, navigate]);

    const UpdateCustomerInfo = (value: FormValueType) => {
        if (!customerInfo) {
            toast.error('Thông tin khách hàng không hợp lệ.');
            return;
        }

        const formData = {
            customerId: accountId,
            email: customerInfo.email,
            phoneNumber: value.phoneNumber || customerInfo.phoneNumber,
            name: value.name || customerInfo.name,
            avatar: value.avatar || customerInfo.avatar,
            password: value.admPassword,
            schools: [
                {
                    schoolId: customerInfo.schools[0].schoolId,
                    type: value.role || customerInfo.schools[0].type,
                    status: value.status || customerInfo.schools[0].status,
                },
            ],
        };

        Modal.confirm({
            style: { fontFamily: 'Quicksand' },
            title: 'Xác nhận cập nhật thông tin tài khoản?',
            async onOk() {
                const response: ResponseDataType<CustomerInfoType> = await patchRequest(
                    '/cms/customer/update-info',
                    formData,
                    'json'
                );
                if (response?.code === 200) {
                    toast.success('Cập nhật tài khoản thành công!');
                    // navigate('/account-list');
                } else {
                    toast.error('Cập nhật tài khoản thất bại!');
                }
            },
        });
    };

    return (
        <div>
            <Row gutter={16}>
                <Col span={8}>
                    <Card title="Chi tiết tài khoản" className={styles.customer_info_card}>
                        <Form form={form} onFinish={UpdateCustomerInfo}>
                            <Row>
                                <Col span={8}>
                                    <div className={styles.card_content_label} style={{ marginTop: 0 }}>
                                        <EmailOutlinedIcon />
                                        &nbsp;&nbsp;Email:
                                    </div>
                                </Col>
                                <Col span={16} style={{ width: '70%', color: 'black', fontWeight: '800', marginTop: -4 }}>
                                    <Form.Item name="email">
                                        <span>{customerInfo?.email}</span>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 0 }}>
                                <Col span={8}>
                                    <div className={styles.card_content_label} style={{ marginTop: 0 }}>
                                        <PhoneOutlinedIcon />
                                        &nbsp;&nbsp;Phone:
                                    </div>
                                </Col>
                                <Col span={16} style={{ width: '70%', color: 'black', fontWeight: '800' }}>
                                    <Form.Item
                                        name="phoneNumber"
                                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <div className={styles.card_content_label} style={{ marginTop: 0 }}>
                                        <PermIdentityOutlined />
                                        &nbsp;&nbsp;Name:
                                    </div>
                                </Col>
                                <Col span={16} style={{ width: '70%', color: 'black', fontWeight: '800' }}>
                                    <Form.Item
                                        name="name"
                                        rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <div className={styles.card_content_label} style={{ marginTop: 0 }}>
                                        <PermIdentityOutlined />
                                        &nbsp;&nbsp;Avatar:
                                    </div>
                                </Col>
                                <Col span={16} style={{ width: '70%', color: 'black', fontWeight: '800' }}>
                                    <Form.Item
                                        name="avatar"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 10 }}>
                                <Col span={8}>
                                    <div className={styles.card_content_label}>
                                        <LockOutlinedIcon />
                                        &nbsp;&nbsp;Mật khẩu
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Form.Item name="admPassword">
                                        <Input.Password placeholder="Mật khẩu" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 0, fontWeight: 'bold', color: 'black' }}>
                                <Col span={8}>
                                    <div className={styles.card_content_label}>
                                        <LockOutlinedIcon />
                                        &nbsp;&nbsp;Xác nhận
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="confirmPassword"
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    // if (!value) return Promise.reject('Cần xác nhận mật khẩu!');
                                                    if (value !== getFieldValue('admPassword')) {
                                                        return Promise.reject('Mật khẩu xác nhận không khớp');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                        style={{ margin: 0 }}
                                    >
                                        <Input.Password placeholder="Xác nhận mật khẩu" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button type="primary" htmlType="submit" style={{ marginTop: 15 }}>
                                Cập nhật tài khoản
                            </Button>
                        </Form>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Thông tin trường" className={styles.customer_info_card}>
                        <Form form={form} onFinish={UpdateCustomerInfo}>
                            <Row>
                                <Col span={8}>
                                    <div className={styles.card_content_label} style={{ marginTop: 0 }}>
                                        <SchoolOutlinedIcon />
                                        &nbsp;&nbsp;Tên trường:
                                    </div>
                                </Col>
                                <Col span={16} style={{ width: '70%', color: 'black', fontWeight: '800', marginTop: -4 }} >
                                    <Form.Item name="schoolName">
                                        <span>{customerInfo?.schools[0].schoolName}</span>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <div className={styles.card_content_label} style={{ marginTop: 0 }}>
                                        <AccountBalanceOutlinedIcon />
                                        &nbsp;&nbsp;MST:
                                    </div>
                                </Col>
                                <Col span={16} style={{ width: '70%', color: 'black', fontWeight: '800', marginTop: -4 }}>
                                    <Form.Item name="mst">
                                        <span>{customerInfo?.schools[0].mst}</span>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <div className={styles.card_content_label} style={{ marginTop: 0 }}>
                                        <PermIdentityOutlined />
                                        &nbsp;&nbsp;Vị trí:
                                    </div>
                                </Col>
                                <Col span={16} style={{ width: '70%', color: 'black', fontWeight: '800', marginTop: -4 }}>
                                    <Form.Item name="role">
                                        <span>{typeList[customerInfo?.schools[0].type || 1]}</span>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
