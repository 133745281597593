import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Button, notification } from 'antd';
import { getRequest, ResponseDataType, postRequest } from 'api';
import { UserOutlined, IdcardOutlined, CalendarOutlined, DollarOutlined, FileTextOutlined, PhoneOutlined, MailOutlined, InfoCircleOutlined } from '@ant-design/icons';
import './BillInfo.css'; // Import tệp CSS
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

interface BillDataType {
  studentInvoiceId: number;
  studentId: number;
  feeTypeId: number;
  content: string;
  cost: number;
  status: number;
  paymentDate: string | null;
  schoolMst: string;
  invoidId: string;
  billId: string;
  sendMail: number;
  createdDate: number;
  updatedDate: number;
  isDelete: number;
  description: string;
  index: string;
  parentPhonenum: string;
  studentName: string;
  month: string;
  year: string;
  schoolYear: string;
  className: string;
  studentCode: string;
}
interface NotificationDataType {
  status: number;
}

const formatCurrency = (value: number): string => {
  const formattedValue = new Intl.NumberFormat('vi-VN').format(value);
  return `${formattedValue} VNĐ`;
};

const statusText = (status: number): string => {
  return status === 0 ? 'Chưa thanh toán' : 'Đã thanh toán';
};

export const BillInfo = () => {
  const [billData, setBillData] = useState<BillDataType | null>(null);
  const { billId } = useParams();
  console.log('billId', billId);

  useEffect(() => {

    const url = `/cms/invoice/detail-invoice/${billId}`;
    const fetchData = async () => {
      try {
        const response: ResponseDataType<BillDataType> = await getRequest(
          url,
        );

        if (response && response.code === 200 && response.info) {
          setBillData(response.info);
        } else {
          console.log(response?.msg || 'Error fetching data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const parsedId: number = Number(billId);
  const handleNotifyClick = async () => {
    const formData = {
      "title": "Thông báo",
      "message": "Thông báo học phí",
      "studentInvoiceId": parsedId
    };
    const response: ResponseDataType<NotificationDataType> = await postRequest('/cms/invoice/send-notification-invoice-to-one', formData, 'json');
    console.log('response', response);

    if (response) {
      if (response.code === 200) {
        toast.success('Gửi thông báo thành công!');
      } else {
        toast.error('Gửi thông báo thất bại!');
        // Change tab in case of error
      }
    };
  };

  if (!billData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Row gutter={16}>
        <Col span={8} >
          <Card title="Chi tiết biên lai" style={{ height: '100%' }}>
            <Row gutter={16}>
              <Col span={8} >
                <div  >
                  <IdcardOutlined /> Mã biên lai:
                </div>
              </Col>
              <Col span={16} >
                <div className="text-spacing">{billData.invoidId}</div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 10 }}>
              <Col span={8} >
                <div  >
                  <UserOutlined /> Mã học sinh:
                </div>
              </Col>
              <Col span={16} >
                <div className="text-spacing">{billData.studentCode}</div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 10 }}>
              <Col span={8} >
                <div  >
                  <UserOutlined /> Tên học sinh:
                </div>
              </Col>
              <Col span={16} >
                <div className="text-spacing">{billData.studentName || '...'}</div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 10 }}>
              <Col span={8} >
                <div  >
                  <FileTextOutlined /> Lớp:
                </div>
              </Col>
              <Col span={16} >
                <div className="text-spacing">{billData.className || '...'}</div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 10 }}>
              <Col span={8} >
                <div  >
                  <CalendarOutlined /> Tháng:
                </div>
              </Col>
              <Col span={16} >
                <div className="text-spacing">{billData.month || '...'}</div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 10 }}>
              <Col span={8} >
                <div  >
                  <CalendarOutlined /> Năm:
                </div>
              </Col>
              <Col span={16} >
                <div className="text-spacing">{billData.year || '...'}</div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 10 }}>
              <Col span={8} >
                <div  >
                  <CalendarOutlined /> Niên Khóa:
                </div>
              </Col>
              <Col span={16} >
                <div className="text-spacing">{billData.schoolYear || '...'}</div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 10 }}>
              <Col span={8} >
                <div  >
                  <PhoneOutlined /> Phụ huynh:
                </div>
              </Col>
              <Col span={8} >
                <div className="text-spacing">{billData.parentPhonenum}</div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Nội dung" style={{ height: '100%' }}>
            <Row gutter={16}>
              <Col span={24} >
                <div >
                  <MailOutlined /> Nội dung biên lai:
                </div>
              </Col>
              <Col span={24} >
                <div className="text-spacing">
                  Tiền tháng {billData.month}/{billData.year}
                </div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 10 }}>
              <Col span={24} >
                <div >
                  <FileTextOutlined /> Ghi chú:
                </div>
              </Col>
              <Col span={24} >
                <div className="text-spacing">{billData.description}</div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 10 }}>
              <Col span={8} >
                <div >
                  <DollarOutlined /> Số tiền:
                </div>
              </Col>
              <Col span={16} >
                <div className="text-spacing">{formatCurrency(billData.cost)}</div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 10 }}>
              <Col span={8} >
                <div >
                  <InfoCircleOutlined /> Trạng thái:
                </div>
              </Col>
              <Col span={16} >
                <div className="text-spacing">{statusText(billData.status)}</div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24} style={{ textAlign: 'center', marginTop: '16px' }}>
                <Button type="primary" onClick={handleNotifyClick}>Gửi thông báo</Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
