import {
  BillInfo,
  BillList,
  CreateBill,
  CreateAccount,
  Home,
  AccountInfo,
  AccountList,
  Profile,
} from 'Pages';
import { ROLE } from 'constant/role';
import path from 'path';

export const ADMIN_ROUTER = [
  {
    key: 'home',
    path: '/',
    element: <Home />,
  },
  {
    key: 'profile',
    path: '/profile',
    navKey: 'pf',
    element: <Profile />,
  },
  {
    key: 'add-account',
    path: '/add-account',
    role: ROLE.ADMIN,
    navKey: 'ac',
    element: <CreateAccount />,
  },
  {
    key: 'account-list',
    path: '/account-list',
    role: ROLE.ADMIN,
    navKey: 'cl',
    element: <AccountList />,
  },
  {
    key: 'account-info',
    path: '/account-list/account-info/:accountId',
    role: ROLE.ADMIN,
    navKey: 'cl',
    subNavKey: 'ci',
    element: <AccountInfo />,
  },
  {
    key: 'add-bill',
    path: '/add-bill',
    role: ROLE.ACCOUNTANT,
    navKey: 'ab',
    element: <CreateBill />,
  },
  {
    key: 'bill-list',
    path: '/bill-list',
    role: ROLE.ACCOUNTANT,
    navKey: 'bl',
    element: <BillList />,
  },
  {
    key: 'bill-info',
    path: '/bill-list/bill-info/:billId',
    role: ROLE.ACCOUNTANT,
    navKey: 'bl',
    subNavKey: 'bi',
    element: <BillInfo />,
  },
];

export const PATH_INFO = {
  '/': { title: 'Trang chủ' },
  '/add-account': { title: 'Thêm tài khoản' },
  '/account-list': {
    title: 'Danh sách tài khoản',
    tabList: ['Admin Trường', 'Hiệu trưởng', 'Giáo viên', 'Kế toán'],
  },
  '/account-list/account-info': {
    title: 'Chi tiết tài khoản',
    // tabList: ['Thông tin chung', 'Danh sách tổ chức'],
  },
  '/add-bill': {
    title: 'Thêm biên lai',
    tabList: ['Thêm biên lai', 'Lỗi thêm biên lai'],
  },
  '/bill-list': {
    title: 'Danh sách biên lai',
    tabList: ['Chưa thanh toán', 'Đã thanh toán'],
  },
  '/bill-list/bill-info': {
    title: 'Chi tiết biên lai',
  },
  '/profile': {
    title: 'Chi tiết tài khoản',
  },
};
