import { useAppContext } from 'Context/AppContext';
import { CreateBillError, CreateNewBill } from './Tabs';

export const CreateBill = () => {
  const { tabNumber } = useAppContext();

  if (tabNumber === 0) return <CreateNewBill />;
  if (tabNumber === 1) return <CreateBillError />;

  return <></>;
};
