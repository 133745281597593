import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { EyeOutlined, MailOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import styles from './authen-page.module.css';
import { ResponseDataType, postRequest } from 'api';
import { ROLE_ENCRYPT } from 'constant/role';

interface LoginDataType {
  id: number;
  email: string;
  roles: string[];
  token: string;
  refresh_token: string;
  schools: any[];
}

export const LoginPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    if (localStorage.getItem('email')) navigate('/');
  });

  const Login = async (value: { email: string; password: string }) => {
    const reqBody = new FormData();
    reqBody.append('email', value.email);
    reqBody.append('password', value.password);

    const response: ResponseDataType<LoginDataType> = await postRequest(
      '/cms/auth/customer/login',
      {
        email: value.email,
        password: value.password,
      },
      'form-data'
    );

    if (response.code !== 200) {
      toast.error(response?.msg);
    } else if (response.info) {
      localStorage.setItem('email', response.info.email);
      localStorage.setItem('uid', response.info.id.toString());
      localStorage.setItem('accessToken', response.info.token);
      localStorage.setItem('refreshToken', response.info.refresh_token);
      localStorage.setItem('schoolId', response.info.schools[0].schoolId.toString());
      if (response.info.roles.length > 1) {
        navigate('/chose-role', { state: { user: response.info } });
      }
      else {
        let roleEncrypt = ROLE_ENCRYPT.find(
          (role) => role.role === response.info?.roles[0]
        )?.encrypted;
        localStorage.setItem('role', roleEncrypt as string);
        window.location.href = '/'
      }
    }
  };

  return (
    <div className={styles.login_page}>
      <div className={styles.login_page_background} />
      <div className={styles.authen_wrapper}>
        <img className={styles.logo} src='/Images/logo.png' alt='' />
        <div className={styles.name}>Vschool</div>
        <Form form={form} onFinish={(value) => Login(value)}>
          <div className={`${styles.form} authen-form`}>
            <Form.Item
              name='email'
              rules={[
                { required: true, message: 'Cần nhập email!' },
                { type: 'email', message: 'Cần nhập email đúng định dạng!' },
              ]}
              style={{ margin: 0 }}
            >
              <Input
                prefix={<MailOutlined className={styles.icon} />}
                placeholder='Email'
                className={styles.form_input}
              />
            </Form.Item>
            <div className={styles.line} />
            <Form.Item
              name='password'
              rules={[{ required: true, message: 'Cần nhập mật khẩu!' }]}
              style={{ margin: 0 }}
            >
              <Input.Password
                prefix={<EyeOutlined className={styles.icon} />}
                placeholder='Mật khẩu'
                className={styles.form_input}
              />
            </Form.Item>
          </div>
          <Button
            type='primary'
            className={styles.authen_button}
            htmlType='submit'
          >
            Đăng nhập
          </Button>
        </Form>
      </div>
    </div>
  );
};
